<template>
  <div>
    <el-dialog
    v-dialogDrag
      title="新增基差转让挂单"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="900px"
    >
      <div>
        <el-form ref="form" :rules="rules" :model="form" label-position="left">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="订单ID" prop="orderId" label-width="120px">
               <el-select class="width-100" v-model="form.orderId">
                <el-option
                  v-for="items in orderIdOptions"
                  :key="items.orderId"
                  :label="items.orderId"
                  :value="items.orderId"
                ></el-option>
              </el-select>
            </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="数量" prop="quantity" label-width="120px">
                <el-input v-model.number="form.quantity"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="基差价" prop="basisPrice" label-width="120px">
                <el-input v-model="form.basisPrice">
                   <template slot="append">元/{{form.util}}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="重量" prop="hangWeight" label-width="120px">
                <el-input v-model="form.hangWeight" :placeholder="'可转让重量 : '+ maxWeight">
                  <template slot="append">{{form.util}}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否接受议价" prop="bargainingStatus" label-width="120px">
                <el-radio-group v-model="form.bargainingStatus" size="small">
                  <el-radio label="T">接受</el-radio>
                  <el-radio label="F">不接受</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="业务负责人" prop="businessName" label-width="120px">
                <el-input v-model="form.businessName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="businessPhone" label-width="120px">
                <el-input v-model="form.businessPhone"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="交易方式" prop="tradeWay" label-width="120px">
                <el-radio-group v-model="form.tradeWay" size="small">
                  <el-radio label="A">整批</el-radio>
                  <el-radio label="P">分销</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          
          </el-row>
        
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    var checkAmount = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/g;
      //  if(value){
      //   if (!reg.test(value)) {
      //   return callback(new Error("请输入正整数"));
      // } else {
      //   callback();
      // }
      // }else{
      //     callback()
      // }
      if (value == "") {
        callback(new Error("请输入数量"));
      } else if (!reg.test(value)) {
        return callback(new Error("请输入正整数"));
      } else {
        callback();
      }
    };
    var checkLowWight = (rule, value, callback) => {
       let reg=/^\d+(\.\d{0,2})?$/;
      if (value=='') {
        return callback(new Error("请输入重量"));
      }
      else if (!reg.test(value)||Number(value)<=0) {
        return callback(new Error("重量必须是正数且不能超过两位小数"));
      } else {
        callback();
      }
    };
    var checkPutHoldWeight = (rule, value, callback) => {
      let reg=/^\d+(\.\d{0,2})?$/;
      if (value=='') {
        return callback(new Error("请输入重量"));
      }
      else if (!reg.test(value)||Number(value)<=0) {
        return callback(new Error("重量必须是正数且不能超过两位小数"));
      } else if(Number(value)>Number(this.maxWeight)){
        return callback(new Error("超过可挂单重量"));
      }else {
        callback()
      }
    };
    return {
      dialogVisible1: false,
      dialogVisible: false,
      gradeName: null,
      form: {
        bargainingStatus: null,//是否可以议价T：可以F：不可以
        basisPrice: null,//基差价
        businessName: null,//业务负责人
        businessPhone: null,//
        orderId: null,//持仓id
        quantity: null,//挂单数量
        tradeWay: null,//A：整批P：分销
        treatyId: 1,//合约id，写死
        hangWeight: null,//挂单重量
      },
      weightUnitOptions:[],
      selectWeightUnit:{},
      rules: {
        orderId: [
          { required: true, message: "请选择订单ID", trigger: "change" }
        ],
        basisPrice: [
          { required: true, message: "请输入基差价", trigger: "change" },
          { validator: checkLowWight, trigger: "change" }
        ],
        bargainingStatus: [
          { required: true, message: "请选择是否可议价", trigger: "change" }
        ],
       
        quantity: [
          { required: true, message: "请输入数量", trigger: "change" },
          { validator: checkAmount, trigger: "change" }
        ],
        hangWeight: [{ required: true, message: "请输入挂单重量", trigger: "change" },{
          validator: checkPutHoldWeight, trigger:'change'
        }],
        tradeWay: [
          { required: true, message: "请选择交易方式", trigger: "change" }
        ],
        businessName: [
          { required: true, message: "请输入业务负责人", trigger: "change" }
        ],
        businessPhone: [
          { required: true, message: "请输入联系电话", trigger: "change" },
          // {
          //   pattern: /^1[3456789]\d{9}$/,
          //   message: "手机号格式不正确",
          //   trigger: "blur"
          // }
        ]
      },
      newFormRules: {
        // code: [{ required: true, message: "请输入短信验证码", trigger: "change" }],
        // marginPassWord: [{ required: true, message: "请输入资金密码", trigger: "change" }],
      },
      putWarehousTimePicker: {
        disabledDate: time => {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      categoryProps: {
        label: "className",
        value: "classId"
      },
      gradeIdProps: {
        label: "value",
        value: "value"
      },
      categoryOptions: [],
      gradeIdOptions: [],
      packagIdOptions: [],
      specificationsIdOptions: [],
      orderIdOptions: [],
      // zjPriceCalc: null,
      isAddLoading: false,
      actionUrl: window.g.APP_USER_PATH_UPLOAD,
      postData: {
        uid: this.$store.state.common.sessionInfo.firmId,
        deleteStatus: 0,
        width: null,
        height: null,
        size: null
      },
      validButtonText: "发送验证码",
      validBtnDisabled: false,
      maxWeight: 0,
      newCommodityId: null
    };
  },
  watch: {
    "form.orderId": {
      handler(val, oldVal) {
        this.getOrderIdOptions(val);
      }
    }
  },
  computed: {
    ...mapGetters(["getMarginRateGetter"]),
    deposit() {
      let currentPrice = util.floatMul(this.form.price, this.form.weight);
      return util.floatMul(currentPrice, this.getMarginRateGetter.marginRate);
    },
    zjPriceCalc() {
      return util.floatMul(this.form.price, this.form.weight);
    }
  },
  methods: {
    handledialogVisible1() {
      this.dialogVisible1 = false;
    },
    showDialog(id) {
      this.dialogVisible = true;
      this.getOrderIdOptions(id);
    },
    getRights() {
      this.loadRight().then((loadResult) => {
        if (loadResult == 'loadRightSuccess') {
          this.right.addStepPrice = this.isHasRight('tc-trade-setPhaseHangResourcePrice');
         
        }
      })
    },
    getfindWeightUnit() {
        http.postFront(protocolFwd.param_findWeightUnit).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.weightUnitOptions = value.content;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
   
    sendValidNo() {
      protocol.param_authn_margin_sendCaptcha.param.id = 30;
      http.postFront(protocol.param_authn_margin_sendCaptcha).then(response => {
        this.$EL_MESSAGE(response.data.message);
        const that = this;
        this.validBtnDisabled = true;
        this.setTime(60, that);
      });
    },
 
    getOrderIdOptions(id) {
      let that = this;
      let params = {
        pickResourcesStatus: ['T'],
        page: 0,
        size:1000,
        pickFirmId: sessionStorage.getItem('userFirmId'),
        sort: [{property: "orderId", direction: "DESC"}],
      }
      protocolFwd.param_queryBasisQueryOrderLd.param = params
      http.postFront(protocolFwd.param_queryBasisQueryOrderLd).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.orderIdOptions = value.content;
           if (id) {
            this.form.orderId = id;
            this.orderIdOptions.forEach(row => {
              if (id == row.orderId) {
                that.form.util = row.name;
                that.maxWeight = util.floatSub(row.orderWeight,row.frozenWeight)
              }
            });
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    calcWeight() {
      let specificationsValue = null;
      for (let i = 0; i < this.specificationsIdOptions.length; i++) {
        if (
          this.form.specificationsId ==
          this.specificationsIdOptions[i].specificationsId
        ) {
          specificationsValue = parseFloat(
            this.specificationsIdOptions[i].value
          );
        }
      }
      this.form.weight = util.floatMul(this.form.quantity, specificationsValue);
    },
    calcZjPrice() {
      this.zjPriceCalc = util.floatMul(this.form.price, this.form.weight);
    },
    handleAvatarSuccess(res, file) {
      this.form.image = res.value;
    },
    beforeAvatarUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 2MB!");
      }
      return testmsg && isLt2M;
    },
    // confirm() {
    //   this.$refs["form"].validate(valid => {
    //     if (valid) {
    //       this.dialogVisible1 = true;
    //     }
    //   });
    // },
    confirm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocolFwd.param_transferOrder.param.bargainingStatus = this.form.bargainingStatus;
          protocolFwd.param_transferOrder.param.orderId = this.form.orderId;
          protocolFwd.param_transferOrder.param.quantity = this.form.quantity;
          protocolFwd.param_transferOrder.param.basisPrice = this.form.basisPrice;
          protocolFwd.param_transferOrder.param.tradeWay = this.form.tradeWay;
          protocolFwd.param_transferOrder.param.hangWeight = this.form.hangWeight;
          protocolFwd.param_transferOrder.param.businessName = this.form.businessName;
          protocolFwd.param_transferOrder.param.businessPhone = this.form.businessPhone;
          const that = this;
          this.isAddLoading = true;
          http
            .postBasisFront(protocolFwd.param_transferOrder)
            .then(response => {
              const { code, message, value } = response.data;
              this.isAddLoading = false;
              if (code == 0) {
                this.dialogVisible = false;
                this.$EL_MESSAGE("提交成功！");
                this.$refs["form"].resetFields();
                this.$emit("reLoad");
                this.$router.push('/sellerCenter/resource/basisTransferRegistration')
              } else {
                this.$EL_MESSAGE(message);
              }
            })
            .catch(() => {
              this.isAddLoading = false;
            });
        }
      });
    },
    handleClose() {
      this.form = {
        bargainingStatus: null,//是否可以议价T：可以F：不可以
        basisPrice: null,//基差价
        businessName: null,//业务负责人
        businessPhone: null,//
        orderId: null,//持仓id
        quantity: null,//挂单数量
        tradeWay: null,//A：整批P：分销
        treatyId: 1,//合约id，写死
        hangWeight: null,//挂单重量
      };
      this.maxWeight = 0
      this.selectWeightUnit = {}
      //  this.newForm = {
      //   code: null,
      //   marginPassWord: null
      // }
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
        // this.$refs["newForm"].clearValidate();
      });
      this.dialogVisible = false;
      this.dialogVisible1 = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.tip {
  color: #cf4640;
  position: absolute;
  top: 9px;
  left: 66px;
}
.stepPriceCon {
  line-height: 30px;
}
.stepPriceBtn {
  width: 80px;
  height: 30px;
  line-height: 30px;
  margin: 10px 30px;
  text-align: center;
  border: 1px solid #66b1ff;
  background-color: #66b1ff;
  color: #fff;
}
</style>