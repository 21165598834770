<template>
  <div id="generalOrderCell">
    <el-row v-if="orderItem" class="generalOrderCellItem">
      <el-row class="cellOne">
        <!-- gzFlag B 基差单，R回购单 -->
        <el-col :span="3">
          <span>订单编号：{{ orderItem.orderId ? orderItem.orderId : '--' }}</span>
        </el-col>
        <!-- <el-col :span="6" v-if="userType == 'buyer'"><span>下单时间：{{ orderItem.pickCreateTime   | DateFormate }}</span></el-col> -->
        <el-col :span="6">
          <span>下单时间：{{ orderItem.orderCreateTime | DateFormate }}</span>
        </el-col>
        <el-col :span="6" class="espli">
          <span>摘单交易商：{{ orderItem.buyEnterpriseName ? orderItem.buyEnterpriseName : '--'}}</span>
        </el-col>
        <el-col :span="6" class="espli">
          <span>挂单交易商：{{ orderItem.sellEnterpriseName ? orderItem.sellEnterpriseName : '--' }}</span>
        </el-col>
        <el-col :span="3">
          <div class="viewDetailsButton">
            <el-button
              size="mini"
              @click="viewDetails(orderItem.pickResourcesId,gzFlag)"
            >查看详情</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row class="cellTwo">
        <el-col :span="22">
          <el-col :span="4">
            <span>{{ orderItem.className ? orderItem.className : '--' }}</span>
          </el-col>
          <el-col :span="4">
            <span>{{ orderItem.orderBasisPrice ? orderItem.orderBasisPrice : '--' }}元/{{ orderItem.name?orderItem.name:'--'}}</span>
          </el-col>
          <el-col :span="3">
            <span>{{ orderItem.tradeWeight ? orderItem.tradeWeight : '--' }}</span>
          </el-col>
          <el-col :span="5">
            <span>{{ orderItem.storageName ? orderItem.storageName : '--' }}</span>
          </el-col>
          <el-col :span="3">
            <span>{{ orderItem.tradeWeight ? orderItem.tradeWeight : '--' }}{{ orderItem.name?orderItem.name:'--' }}</span>
          </el-col>
          <el-col :span="5">
            <span>{{ orderItem.pickResourcesStatus | basisPickStatusFormate }}</span>
          </el-col>
        </el-col>
      </el-row>
      <el-row></el-row>
      <el-row class="cellThree">
        <el-col :span="1">
          <span>总计</span>
        </el-col>
        <!-- <el-col :span="2"><span>数量：{{ orderItem.tradeWeight ? orderItem.tradeWeight : '--' }}</span></el-col> -->
        <el-col :span="2">
          <span>重量：{{ orderItem.tradeWeight ? orderItem.tradeWeight : '--' }}{{ orderItem.name?orderItem.name:'--' }}</span>
        </el-col>
        <el-col :span="11">
          <span>
            总价：
            <span
              class="redColor"
            >&nbsp;{{ (orderItem.orderBasisPrice * orderItem.tradeWeight).toFixed(2) }}元</span>
          </span>&emsp;
          <span v-if="orderItem.bargainingBasisPrice&&orderItem.pickResourcesStatus !== 'F' && orderItem.pickResourcesStatus !== 'U'">
            议价单价：
            <span class="redColor">&nbsp;¥ {{ orderItem.bargainingBasisPrice }}</span>&nbsp;&nbsp;
            <span>议价状态：{{orderItem.pickBargainingStatus|priceStatusFormate}}</span>
          </span>
        </el-col>
        <!-- 撤单，付首款(订单状态已挂单 付款状态待付款) -->
        <!-- 买家付首款 -->
        <el-col :span="9" class="cellMethod" v-if="userType == 'buyer'">
          <span
            v-if="orderItem.pickResourcesStatus === 'W' && (Number(hour)||Number(min)||Number(second))"
          >确认倒计时：{{hour}} 时{{min}} 分{{second}} 秒</span>
          <span v-else-if="orderItem.pickResourcesStatus === 'T'"></span>
          <span v-else>订单已失效或已取消</span>
          <el-button
            v-if="orderItem.pickResourcesStatus === 'T'&&(Number(orderItem.orderWeight)-Number(orderItem.frozenWeight)>0)"
            plain
            type="danger"
            size="mini"
            @click="buyBackOrder(orderItem.orderId,orderItem.orderWeight,orderItem.frozenWeight)"
          >回购</el-button>
          <el-button
            v-if="right.transferOrder&&orderItem.pickResourcesStatus === 'T'&&(Number(orderItem.orderWeight)-Number(orderItem.frozenWeight)>0)"
            plain
            type="danger"
            size="mini"
            @click="transferOrder(orderItem.orderId)"
          >转让</el-button>
          <el-button
            v-if="right.cancelOrder&&orderItem.pickResourcesStatus === 'W'&&orderItem.pickResourcesStatus !== 'F' && orderItem.pickResourcesStatus !== 'U'"
            plain
            type="danger"
            size="mini"
            @click="cancelOrder(orderItem.pickResourcesId)"
          >撤单</el-button>
          <el-button
            v-if="right.confirmBuyPickResource&&orderItem.pickResourcesStatus === 'W'&&orderItem.pickResourcesStatus !== 'F' && orderItem.pickResourcesStatus !== 'U'"
            plain
            type="danger"
            size="mini"
            @click="showConfirmOrderDialog(orderItem)"
          >确认订单</el-button>
          <el-button
            v-if="right.bargainingHangOrder&&orderItem.pickResourcesStatus === 'W'"
            plain
            type="danger"
            size="mini"
            @click="bargainingHangOrder(orderItem.orderId, orderItem.pickResourcesId,orderItem.hangFirmId)"
          >议价</el-button>
        </el-col>
        <!-- 卖家确认订单 -->
        <el-col :span="9" v-if="userType === 'seller'" class="cellMethod">
          <span
            v-if="orderItem.pickResourcesStatus === 'W'||orderItem.pickResourcesStatus === 'T'||orderItem.pickBargainingStatus!='N'"
          ></span>
          <span v-else>订单已失效或已取消</span>
          <el-button
            v-if="orderItem.pickResourcesStatus === 'T'&&(Number(orderItem.orderWeight)-Number(orderItem.frozenWeight)>0)&&gzFlag=='MC'"
            plain
            type="danger"
            size="mini"
            @click="buyBackOrder(orderItem.orderId,orderItem.orderWeight,orderItem.frozenWeight)"
          >回购</el-button>
          <!-- <el-button v-if="orderItem.hangFirmId != sessionInfoGetter.firmId" :disabled="orderItem.pickResourcesStatus === 'F' || orderItem.pickResourcesStatus === 'U'" plain type="danger" size="mini" @click="cancelOrder(orderItem.pickResourcesId)">撤单</el-button> -->
          <el-button
            v-if="right.bargainingHangOrders&&orderItem.pickResourcesStatus === 'W'&&orderItem.pickBargainingStatus !== 'N'"
            plain
            type="danger"
            size="mini"
            @click="bargainingHangOrder(orderItem.orderId, orderItem.pickResourcesId,orderItem.hangFirmId)"
          >议价</el-button>
        </el-col>
        <!-- 买家回购转让，卖家回购 -->
      </el-row>
    </el-row>
    <!-- 确认订单 -->
    <el-dialog
    v-dialogDrag
      :title="dialogTitle"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose"
    >
      <el-form ref="dialogForm" :rules="rules" :model="dialogForm">
        <el-form-item label="短信验证码" prop="code">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-row :gutter="20">
                <el-col :span="16">
                  <el-input v-model="dialogForm.code" placeholder="短信验证码"></el-input>
                </el-col>
                <el-col :span="8">
                  <el-button
                    class="width-100"
                    :disabled="validBtnDisabled"
                    @click="sendValidNo()"
                  >{{ validButtonText }}</el-button>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="资金密码" prop="password">
          <el-input v-model="dialogForm.password" type="password" placeholder="资金密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="confirmDialog()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 订单详情 -->
    <orderDetails ref="orderDetails" :orderType="orderType" />
    <!-- 补入库 -->
    <supplementaryDialog ref="supplementaryDialog" @reLoad="reLoadData()" />
    <!-- 转让 -->
    <transferDialog ref="transferDialog" @reLoad="reLoadData()" />
    <!-- 议价是否同意 -->
    <el-dialog
    v-dialogDrag
      title="是否同意议价"
      :visible.sync="dialogVisible1"
      width="390px"
      :before-close="handleClose1"
    >
      <!-- <span></span> -->
      <el-select v-model="agreeOrRefuce" placeholder="请选择是否同意议价" style="width:100%;">
        <el-option
          v-for="item in AgreeOrRefuceOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose1">取 消</el-button>
        <el-button type="primary" @click="sellIsAgreeOrRefuce">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import orderDetails from "./generalOrderDetails";
import md5 from "js-md5";
import supplementaryDialog from "../../sellerCenter/resourceMgt/warehousingApply/supplementaryDialog";
import transferDialog from "../../sellerCenter/resourceMgt/basisTransferRegistration/addDialog";
export default {
  props: {
    orderItem: Object,
    userType: String,
    gzFlag: String,
    orderType: String,
    right:Object
  },
  components: {
    orderDetails,
    transferDialog,
    supplementaryDialog
  },
  data() {
    return {
      fatherFrimId:sessionStorage.getItem("userFirmId"),
      timeOut: null,
      day: 0,
      hour: "00",
      min: "00",
      second: "00",
      // right: {
      //   transferOrder: false,
      //   repoOrderForFirm: false,
      //   repoOrderForMember: false,
      //   cancelOrder: false,
      //   bargainingHangOrder: false,
      //   bargainingHangOrders: false,
      //   confirmBuyPickResource: false
      // },
      AgreeOrRefuceOptions: [
        {
          value: "T",
          label: "同意"
        },
        {
          value: "F",
          label: "拒绝"
        }
      ],
      agreeOrRefuce: null,
      currentpickResourcesId: null, // 议价时的id
      dialogVisible: false,
      dialogVisible1: false,
      validButtonText: "发送验证码",
      dialogForm: {
        code: null,
        password: null
      },
      isConfirm:false,
      isCancle:false,
      isHuigou:false,
      rules: {
        code: [
          { required: true, message: "请输入短信验证码", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入资金密码", trigger: "blur" }
        ]
      },
      validBtnDisabled: false,
      dialogOrderId: null,
      dialogTitle: null,
      dialogType: null, // 1: 确认订单  2: 付尾款
    };
  },
  beforeDestroy() {
    if (this.timeOut) {
      clearInterval(this.timeOut);
      this.timeOut = null;
    }
  },
  mounted() {
    this.timeClick();
    // this.getRights();
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  watch: {
    "orderItem.pickResourcesId": {
      handler(val, oldVal) {
        this.timeClick();
      }
    }
  },
  methods: {
    setTime(e, vueThis) {
      let _this = this;
      e--;
      vueThis.validButtonText = e + "s";
      if (e > 0) {
        setTimeout(function() {
          _this.setTime(e, vueThis);
        }, 1000);
      } else {
        vueThis.validButtonText = "发送验证码";
        vueThis.validBtnDisabled = false;
      }
    },
    sendValidNo() {
      protocol.param_authn_margin_sendCaptcha.param.id = 30;
      http.postFront(protocol.param_authn_margin_sendCaptcha).then(response => {
        this.$EL_MESSAGE(response.data.message);
        const that = this;
        this.validBtnDisabled = true;
        this.setTime(60, that);
      });
    },
    // getRights() {
    //   this.loadRight().then(loadResult => {
    //     if (loadResult == "loadRightSuccess") {
    //       this.right.transferOrder = this.isHasRight(
    //         "basis-trade-transferOrder"
    //       ); // 转让
    //       this.right.repoOrderForFirm = this.isHasRight(
    //         "basis-trade-repoOrderForFirm"
    //       ); // 用户发起回购
    //       this.right.repoOrderForMember = this.isHasRight(
    //         "basis-trade-repoOrderForMember"
    //       ); // 会员发起回购
    //       this.right.cancelOrder = this.isHasRight(
    //         "basis-trade-undoPickResource"
    //       ); // 撤销摘单
    //       this.right.bargainingHangOrder = this.isHasRight(
    //         "basis-trade-pickResourceBargain"
    //       ); //摘单方 议价
    //       this.right.bargainingHangOrders = this.isHasRight(
    //         "basis-trade-updatePickResourceBargainStatus"
    //       ); //挂单方 议价确认
    //       this.right.confirmBuyPickResource = this.isHasRight(
    //         "basis-trade-confirmBuyPickResource"
    //       ); //卖方确认摘单
    //     }
    //   });
    // },
    timeClick() {
      if (this.orderItem && this.orderItem.pickCreateTime) {
        // 20分钟的倒计时
        let endPayTime = Number(this.orderItem.pickCreateTime) + 1200000;
        this.countTime(endPayTime);
        this.timeOut = setInterval(() => {
          this.countTime(endPayTime);
          if (
            this.day == 0 &&
            this.hour == "00" &&
            this.min == "00" &&
            this.second == "00"
          ) {
            clearInterval(this.timeOut);
            this.timeOut = null;
          }
        }, 1000);
      }
    },
    getToday() {
      var date = new Date();
      var day = date.getTime();
      // var year = date.getFullYear();
      // var month = date.getMonth() + 1;
      // var day = date.getDate();
      // var hour = date.getHours();
      // var min = date.getMinutes();
      // var sec = date.getSeconds();
      // [year, month, day, hour, min, sec] = [year, month, day, hour, min, sec].map(
      //   item => (item < 10 ? "0" + item : item)
      // );
      return Number(`${day}`);
    },
    countTime(endPayTime) {
      // 获取当前时间
      const date = new Date();
      const now = date.getTime();
      // 设置截止时间
      // const endDate = new Date(endPayTime) // this.curStartTime需要倒计时的日期
      // const end = endDate.getTime()
      const end = Number(endPayTime);
      // 时间差
      const leftTime = end - now;
      let day = "";
      let hour = "";
      let min = "";
      let second = "";
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 天
        day = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        // 时
        let h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        hour = h < 10 ? "0" + h : h;
        // 分
        let m = Math.floor((leftTime / 1000 / 60) % 60);
        min = m < 10 ? "0" + m : m;
        // 秒
        let s = Math.floor((leftTime / 1000) % 60);
        second = s < 10 ? "0" + s : s;
      } else {
        day = 0;
        hour = "00";
        min = "00";
        second = "00";
      }
      this.day = day;
      this.hour = hour;
      this.min = min;
      this.second = second;
    },
    showConfirmOrderDialog(orderItem) {
        let money = null
        let marginRate = window.g.marginRate
        console.log(marginRate)
      if(orderItem.type=='BO'||orderItem.type=='SO'){
         if(orderItem.pickBargainingStatus=='T'){
          let calcMoney = Number(orderItem.bargainingBasisPrice)* Number(orderItem.tradeWeight)*Number(marginRate)  
          money=  Number(calcMoney).toFixed(2)
          this.dialogTitle = "付首笔款: ￥"+money;
        }else{
          let calcMoney = Number(orderItem.orderBasisPrice)* Number(orderItem.tradeWeight)*Number(marginRate)
          money=Number(calcMoney).toFixed(2)
          this.dialogTitle = "付首笔款: ￥"+money;
        }
      }else if(this.userType=='buyer'&&orderItem.type=='BA'){
        if(orderItem.pickBargainingStatus=='T'){
        let calcMoney = Number(Number(orderItem.bargainingBasisPrice)* Number(orderItem.tradeWeight)).toFixed(2)
        this.dialogTitle = "付全款: ￥"+calcMoney;
        }else{
           let calcMoney = Number(Number(orderItem.orderBasisPrice)* Number(orderItem.tradeWeight)).toFixed(2)
         this.dialogTitle = "付全款: ￥"+calcMoney;
        }
      }else{
        this.dialogTitle = "确认订单";
      }
      this.dialogOrderId = orderItem.pickResourcesId;
      this.dialogVisible = true;
      this.dialogType = 1;
    },
    showPayEndMoneyDialog(orderId) {
      this.dialogTitle = "付剩余款";
      this.dialogOrderId = orderId;
      this.dialogVisible = true;
      this.dialogType = 2;
    },
    confirmDialog() {
      this.$refs["dialogForm"].validate(valid => {
        if (valid) {
          // 确认订单
          this.confirmOrder();
        }
      });
    },
    confirmOrder() {
      this.$confirm('确定'+this.dialogTitle+'吗？', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
            if(!this.isConfirm){
            this.isConfirm = true
          }else{
            return
          }
          let newprotocolFwd = protocolFwd.param_confirmBuyBasisPickResource;
          newprotocolFwd.param.pickResourcesId = this.dialogOrderId;
          newprotocolFwd.param.code = this.dialogForm.code;
          newprotocolFwd.param.marginPassWord = md5(
            "47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=" +
              this.dialogForm.password
          );
          // console.log( newprotocolFwd)
          http.postFront(newprotocolFwd).then(response => {
            const { code, message, value } = response.data;
              this.isConfirm = false
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.$emit("reLoad");
              this.handleClose();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => { this.isConfirm = false});
    },
    cancelOrder(pickResourcesId) {
      this.$confirm("确定撤销该订单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
           if(!this.isCancle){
            this.isCancle = true
          }else{
            return
          }
          protocolFwd.param_undoBasisPickResource.param.pickResourcesId = pickResourcesId;
          http
            .postFront(protocolFwd.param_undoBasisPickResource)
            .then(response => {
                 this.isCancle = false
              const { code, message, value } = response.data;
              if (code == 0) {
                this.$EL_MESSAGE(message);
                this.$emit("reLoad");
              } else {
                this.$EL_MESSAGE(message);
              }
            });
        })
        .catch(() => {   this.isCancle = false});
    },
    bargainingHangOrder(orderId, pickResourcesId, hangFirmId) {
      if (this.userType === "buyer" && this.right.bargainingHangOrder) {
        this.$prompt("请输入议价单价", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        })
          .then(({ value }) => {
            let reg = /^\d+(\.\d{0,2})?$/;
            if (value == "") {
              return this.$EL_MESSAGE("请输入议价单价");
            } else if (!reg.test(value) || Number(value) <= 0) {
              return this.$EL_MESSAGE("议价单价必须是正数且不能超过两位小数");
            }
            // protocolFwd.param_pickResourceBargain.param.orderId = orderId
            protocolFwd.param_pickBasisResourceBargain.param.pickResourcesId = pickResourcesId;
            protocolFwd.param_pickBasisResourceBargain.param.bargainPrice = value;
            http
              .postFront(protocolFwd.param_pickBasisResourceBargain)
              .then(response => {
                const { code, message, value } = response.data;
                if (code == 0) {
                  this.$EL_MESSAGE(message);
                  this.$emit("reLoad");
                } else {
                  this.$EL_MESSAGE(message);
                }
              });
          })
          .catch(() => {});
      } else if (
        this.userType === "seller" &&
        this.right.bargainingHangOrders
      ) {
        this.dialogVisible1 = true;
        this.currentpickResourcesId = pickResourcesId;
        // this.$prompt("请输入议价状态(同意T,拒绝F)", "提示", {
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消"
        // })
        //   .then(({ value }) => {

        //     protocolFwd.param_updateBasisPickResourceBargainStatus.param.pickResourcesId = pickResourcesId;
        //     protocolFwd.param_updateBasisPickResourceBargainStatus.param.pickBargainingStatus = value;
        //     http
        //       .postFront(protocolFwd.param_updateBasisPickResourceBargainStatus)
        //       .then(response => {
        //         const { code, message, value } = response.data;
        //         if (code == 0) {
        //           this.$EL_MESSAGE(message);
        //           this.$emit("reLoad");
        //         } else {
        //           this.$EL_MESSAGE(message);
        //         }
        //       });
        //   })
        //   .catch(() => {});
      } else {
        this.$EL_MESSAGE("暂无此权限！");
      }
    },
    sellIsAgreeOrRefuce() {
      if (!this.agreeOrRefuce) {
        this.$EL_MESSAGE("请选择是否同意议价");
        return;
      }
      protocolFwd.param_updateBasisPickResourceBargainStatus.param.pickResourcesId = this.currentpickResourcesId;
      protocolFwd.param_updateBasisPickResourceBargainStatus.param.pickBargainingStatus = this.agreeOrRefuce;
      http
        .postFront(protocolFwd.param_updateBasisPickResourceBargainStatus)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.$EL_MESSAGE(message);
            this.$emit("reLoad");
            this.handleClose1();
          } else {
            this.$EL_MESSAGE(message);
          }
        })
        .catch(() => {
          this.handleClose1();
        });
    },
    viewDetails(id, pickResourcesId) {
      this.$refs.orderDetails.showDialog(id, pickResourcesId);
    },
    goSupplementary(id) {
      this.$refs.supplementaryDialog.showDialog(id);
    },
    reLoadData() {
      this.$emit("reLoad");
    },
    buyBackOrder(orderId, orderWeight, frozenWeight) {
      let canWeight = Number(orderWeight) - Number(frozenWeight);
      let regAmount = /^\d+(\.\d{0,2})?$/;
      if (this.userType === "buyer" && this.right.repoOrderForFirm) {
        this.$prompt(
          "请输入回购重量(可回购重量:" + canWeight + ")",
          "回购申请",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消"
          }
        )
          .then(({ value }) => {
            if (value == null || value == "") {
              this.$EL_MESSAGE("请输入回购重量");
              return;
            } else if (!regAmount.test(value) || Number(value) <= 0) {
              this.$EL_MESSAGE("重量必须是正数且不能超过两位小数");
              return;
            } else if (Number(canWeight) < Number(value)) {
              console.log(value);
              this.$EL_MESSAGE("不能超过可回购重量");
              return;
            }
              if(!this.isHuigou){
                this.isHuigou = true
              }else{
                return
              }
            protocolFwd.param_repoOrderForFirm.param.orderId = orderId;
            protocolFwd.param_repoOrderForFirm.param.repoWeight = value;
            http
              .postFront(protocolFwd.param_repoOrderForFirm)
              .then(response => {
                this.isHuigou = false
                const { code, message, value } = response.data;
                if (code == 0) {
                  this.$EL_MESSAGE(message);
                  this.$emit("reLoad");
                   this.$router.push({
                    path: "/buyerCenter/purchase/basisBuybackOrder"
                  })
                } else {
                  this.$EL_MESSAGE(message);
                }
              });
          })
          .catch(() => {   this.isHuigou = false});
      } else if (this.right.repoOrderForMember && this.userType === "seller") {
        this.$prompt(
          "请输入回购重量( 可回购重量 : " + canWeight + " )",
          "回购申请",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消"
          }
        )
          .then(({ value }) => {
            if (value == "") {
              this.$EL_MESSAGE("请输入回购重量");
              return;
            } else if (!regAmount.test(value) || Number(value) <= 0) {
              this.$EL_MESSAGE("重量必须是正数且不能超过两位小数");
              return;
            } else if (Number(canWeight) < Number(value)) {
              this.$EL_MESSAGE("不能超过可回购重量");
              return;
            }
            if(!this.isHuigou){
                this.isHuigou = true
              }else{
                return
              }
            protocolFwd.param_repoOrderForMember.param.orderId = orderId;
            protocolFwd.param_repoOrderForMember.param.repoWeight = value;
            http
              .postFront(protocolFwd.param_repoOrderForMember)
              .then(response => {
                 this.isHuigou = false
                const { code, message, value } = response.data;
                if (code == 0) {
                  this.$EL_MESSAGE(message);
                  this.$emit("reLoad");
                   this.$router.push({
                    path: "/sellerCenter/sales/basisRepoOrder"
                  })
                } else {
                  this.$EL_MESSAGE(message);
                }
              });
          })
          .catch(() => { this.isHuigou = false});
      } else {
        this.$EL_MESSAGE("暂无此权限！");
      }
    },
    transferOrder(id) {
      this.$refs.transferDialog.showDialog(id);
    },
    handleClose() {
      this.dialogTitle = null;
      this.dialogOrderId = null;
      this.dialogVisible = false;
      this.dialogType = null;
      this.dialogForm = {
        code: null,
        password: null
      };
    },
    handleClose1() {
      this.dialogVisible1 = false;
      this.agreeOrRefuce = null;
      this.currentpickResourcesId = null;
    }
  }
};
</script>
<style scoped lang="scss">
#generalOrderCell {
  line-height: 35px;
}
#generalOrderCell .generalOrderCellItem {
  text-align: center;
}
#generalOrderCell .cellOne {
  margin-top: 10px;
  border-top: 1px solid #ebeef5;
  border-left: 1px solid #ebeef5;
  border-right: 1px solid #ebeef5;
}
#generalOrderCell .cellTwo {
  border-top: 1px solid #ebeef5;
  border-left: 1px solid #ebeef5;
  border-right: 1px solid #ebeef5;
}
#generalOrderCell .cellThree {
  border-top: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
  border-left: 1px solid #ebeef5;
  border-right: 1px solid #ebeef5;
}
.redColor {
  color: rgb(210, 29, 39);
}
.cellMethod {
  padding-right: 5px;
  float: right;
  text-align: right;
}
.viewDetailsButton {
  // margin-right: 30px;
  text-align: right;
}
.espli {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right:10px;
  box-sizing: border-box;
}
</style>
