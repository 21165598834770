<template>
  <el-dialog
    title="订单详情"
    v-dialogDrag
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="900px"
  >
    <div class="viewDetails">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">订单编号：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.orderId }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">下单时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.orderCreateTime | DateFormate }}</p>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品分类：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.className }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12" v-for="(item,index) of form.specificationsName" :key="index">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品属性：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ item.label }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">最后点价日：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.somePriceTime | DateFormateDay }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">初始交收日：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.deliveryTime | DateFormateDay }}</p>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">单价：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.orderBasisPrice }}元/{{form.name}}</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">数量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.quantity }}</p>
            </el-col>
          </el-row>
        </el-col>-->
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">重量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.tradeWeight }}{{form.name}}</p>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">生产厂家：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.manufacturer }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">交收仓库：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.storageName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">总价格：</p>
            </el-col>
            <el-col :span="16">
              <p
                class="text"
              >{{ (Number(form.orderBasisPrice)*Number(form.tradeWeight)).toFixed(2) }}元</p>
              <!-- <p v-if="orderType != 'bidding'" class="text">{{ form.zjPrice }}元</p>
              <p v-if="orderType == 'bidding'" class="text">{{ form.offerPrice }}元</p>-->
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">卖方信息：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.sellEnterpriseName }}</p>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">买方信息：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.buyEnterpriseName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">状态：</p>
            </el-col>
            <el-col :span="16">
              <p
                v-if="form.pickResourcesStatus !== 'F' && form.pickResourcesStatus !== 'U'"
                class="text"
              >{{ form.pickResourcesStatus | basisPickStatusFormate }}</p>
              <p v-else>--</p>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    orderType: String,
    gzFlag: String
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        // warehousId: null,
        // price: null
      }
    };
  },
  methods: {
    showDialog( pickResourcesId,gzFlag) {
      this.dialogVisible = true;
       console.log(this.gzFlag)
      this.getDetails(pickResourcesId,gzFlag);
    },
    getDetails(pickResourcesId,gzFlag) {
      if (gzFlag != "MC") {
        protocolFwd.param_queryBasisQueryOrderLd.param.pickResourcesId = pickResourcesId;
        protocolFwd.param_queryBasisQueryOrderLd.param.size = 1;
        protocolFwd.param_queryBasisQueryOrderLd.param.page = 0;
        protocolFwd.param_queryBasisQueryOrderLd.param.orderId = null;
        protocolFwd.param_queryBasisQueryOrderLd.param.startTime = null;
        protocolFwd.param_queryBasisQueryOrderLd.param.endTime = null;

        http
          .postFront(protocolFwd.param_queryBasisQueryOrderLd)
          .then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              value.content[0].specificationsName = this.attributeTrans(
                value.content[0].specificationsName
              );
              this.form = value.content[0];
              for (let i in this.form) {
                if (!this.form[i] && this.form[i] != 0) {
                  this.form[i] = "--";
                }
              }
            } else {
              this.$EL_MESSAGE(message);
            }
          });
      } else {
        protocolFwd.param_queryBasisMemberQueryOrderLd.param.pickResourcesId = pickResourcesId;
        protocolFwd.param_queryBasisMemberQueryOrderLd.param.size = 1;
        protocolFwd.param_queryBasisMemberQueryOrderLd.param.page = 0;
        protocolFwd.param_queryBasisMemberQueryOrderLd.param.orderId = null;

        protocolFwd.param_queryBasisMemberQueryOrderLd.param.startTime = null;
        protocolFwd.param_queryBasisMemberQueryOrderLd.param.endTime = null;

        http
          .postFront(protocolFwd.param_queryBasisMemberQueryOrderLd)
          .then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              value.content[0].specificationsName = this.attributeTrans(
                value.content[0].specificationsName
              );
              this.form = value.content[0];
              for (let i in this.form) {
                if (!this.form[i] && this.form[i] != 0) {
                  this.form[i] = "--";
                }
              }
            } else {
              this.$EL_MESSAGE(message);
            }
          });
      }
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>
<style scoped>
.viewDetails {
  line-height: 40px;
}
.viewDetails .title {
  text-align: right;
}
.viewDetails .text {
  text-align: left;
}
.proPictureImg {
  max-width: 80%;
}
</style>