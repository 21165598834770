<template>
  <!-- <div>通用订单</div> -->
  <div id="generalOrder">
    <el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-col :span="7">
            <p class="searchItemTitle">订单编号</p>
          </el-col>
          <el-col :span="17">
            <el-input
              placeholder="请输入订单编号"
              v-model="searchForm.orderId"
            >
            </el-input>
          </el-col>
        </el-col>
        <el-col :span="8">
          <el-col :span="5">
            <p class="searchItemTitle">下单时间</p>
          </el-col>
          <el-col :span="19">
            <el-date-picker
              class="width-100" 
              v-model="searchForm.orderTime"
              type="daterange"
              range-separator="至"
              start-placeholder="下单开始日期"
              end-placeholder="下单结束日期"
              value-format="timestamp"
              >
            </el-date-picker>
          </el-col>
        </el-col>
        <el-col :span="6">
          <el-button type="danger" @click="getSearchDatas()">搜索</el-button>
          <el-button @click="resetSearch()">重置</el-button>
        </el-col>
      </el-row>
      <el-col :span="23" v-if="gzFlag!='MC'">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" text-color="#000" active-text-color="rgb(210, 29, 39)">
          <el-menu-item index="1">全部</el-menu-item>
          <el-menu-item index="2">待付款</el-menu-item>
          <el-menu-item index="3">已完成</el-menu-item>
          <el-menu-item index="4">已取消</el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="23" class="darkColor">
        <el-col :span="22">
          <el-col :span="4"><p class="tableHeaderItem">商品分类</p></el-col>
          <el-col :span="4"><p class="tableHeaderItem">单价</p></el-col>
          <el-col :span="3"><p class="tableHeaderItem">数量</p></el-col>
          <el-col :span="5"><p class="tableHeaderItem">仓库</p></el-col>
          <el-col :span="3"><p class="tableHeaderItem">重量</p></el-col>
          <el-col :span="5"><p class="tableHeaderItem">状态</p></el-col>
        </el-col>
      </el-col>
      <el-col :span="23" class="orderItem" v-for="(orderItem,index) in orders" :key="index">
        <general-order-cell :orderItem="orderItem" @reLoad="getSearchDatas" :userType="userType" :gzFlag="gzFlag" :orderType="orderType" :right="right"></general-order-cell>
      </el-col>
    </el-row>
    <div v-if="orders.length == 0" class="noData">
      <p>暂无数据</p>
    </div>
  </div>
</template>
<script>
import generalOrderCell from './generalOrderCell'
export default {
  props:{
    orders: Array,
    userType: String,
    gzFlag: String,
    orderType: String
  },
  components:{
    'general-order-cell':generalOrderCell
  },
  data(){
    return{
      activeIndex: '1',
      searchForm: {
        orderId: null,
        startTime: null,
        endTime: null,
        orderTime: null,
        pickType: null,
        pickStatus: null,
      },
      right: {
        transferOrder: false,
        repoOrderForFirm: false,
        repoOrderForMember: false,
        cancelOrder: false,
        bargainingHangOrder: false,
        bargainingHangOrders: false,
        confirmBuyPickResource: false
      },
    }
  },
  mounted(){
      this.getRights();
  },
  methods:{
    resetSearch() {
      this.searchForm = {
        orderId: null,
        startTime: null,
        endTime: null,
        orderTime: null,
        pickResourcesStatus:null
      }
      this.getSearchDatas();
    },
       getRights() {
      this.loadRight().then(loadResult => {
        if (loadResult == "loadRightSuccess") {
          this.right.transferOrder = this.isHasRight(
            "basis-trade-transferOrder"
          ); // 转让
          this.right.repoOrderForFirm = this.isHasRight(
            "basis-trade-repoOrderForFirm"
          ); // 用户发起回购
          this.right.repoOrderForMember = this.isHasRight(
            "basis-trade-repoOrderForMember"
          ); // 会员发起回购
          this.right.cancelOrder = this.isHasRight(
            "basis-trade-undoPickResource"
          ); // 撤销摘单
          this.right.bargainingHangOrder = this.isHasRight(
            "basis-trade-pickResourceBargain"
          ); //摘单方 议价
          this.right.bargainingHangOrders = this.isHasRight(
            "basis-trade-updatePickResourceBargainStatus"
          ); //挂单方 议价确认
          this.right.confirmBuyPickResource = this.isHasRight(
            "basis-trade-confirmBuyPickResource"
          ); //卖方确认摘单
        }
      });
    },
   handleSelect(tab) {
      switch(tab) {
        case '1':
          // 全部
          this.searchForm.pickResourcesStatus = null
          break;
        case '2':
          // 待付款
          this.searchForm.pickResourcesStatus = ['W']
          break;
        case '3':
          // 已完成
          this.searchForm.pickResourcesStatus = ['T']
          break;
        case '4':
          // 已取消
          this.searchForm.pickResourcesStatus = ['F','U']
          break;
        
      }
      this.getSearchDatas();
    },
    getSearchDatas(){
      if (this.searchForm.orderTime && this.searchForm.orderTime.length !== 0) {
        this.searchForm.startTime = this.searchForm.orderTime[0];
        this.searchForm.endTime = this.searchForm.orderTime[1];
      }
      this.$emit('reLoad', this.searchForm);
    }
  }
}
</script>
<style scoped lang="scss">
.headerText{
  padding-left: 20px;
  font-size: 14px;
  line-height: 40px;
  color: #9999a2;
}
.darkColor{
  margin-top: 5px;
  padding: 5px 0;
  background: rgba(5, 64, 106, 1);
}
.tableHeaderItem{
  color: #fff;
  text-align: center;
  line-height: 30px;
}
.searchItemTitle {
  line-height: 40px;
}
</style>